@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	@include flex('center', 'center');
	height: 100%;
	width: 100%;

	.Login-logo {
		position: absolute;
		top: 2em;
		left: calc(50% - 0.5 * 15.33em);
		width: 15.33em;
		height: 6.78em;
	}

	.Login-box {
		position: relative;
		width: 27.13em;
		color: $text-dark;
		background-color: $white;
		border-radius: 0.56em;
		padding: 2em 6em 2.5em 6em;

		.Login-form {
			p {
				color: $text-dark;
				font-size: 1.5em;
				font-weight: bold;
				text-align: center;
				margin-top: 0;
			}
			input {
				display: block;
				font-size: 1.11em;
				width: 100%;
				height: 2.3em;				
				border-radius: 0.5em;
				color: $text-dark;
				background-color: $lightBlue;
				margin: 0.75em auto;		
				padding: 0.5em;
				@include placeholderColor($text-dark);
			}
			// Fixing chrome setting the background color to white on autofill
			input:-webkit-autofill {
				// For some reason, chrome does not allow background-color on autofill
				// This is solved here by settings a large inset box-shadow with the desired background color
				box-shadow: 0 0 0 10em $offwhite inset !important;
				-webkit-text-fill-color: $text-dark;
			}

			.Login-errorMessage {
				pointer-events: none;
				height: 1.5em;
				margin: 0.6em;
				margin-bottom: 1.5em;
				width: 12em;
				font-size: 0.85em;
				color: red;
				text-align: center;
			}
		}
	}
}