@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: 
		linear-gradient(
			to bottom,
			$skyBlue1 20%,
			$skyBlue2 45%,
			$skyBlue3 65%,
			$skyBlue4 75%,
			$skyBlue1 10%
		);
	background-size: 100% 100%;
	
	.Background-clouds {
		position: absolute;
		top: 7.816em;
		left: 0;
		width: 100%;
		height: 15.7816em;
		background-image: url(../../../assets/images/clouds.svg);
		background-position: top center;
	}

	.Background-skyline {
		position: absolute;
		left: 0;
		bottom: 25%; // 13.25em;
		width: 100%;
		height: 8.72em;
		background-image: url(../../../assets/images/background-skyline.png);
		background-size: auto 100%;
		background-repeat: repeat-x;
		background-position: top left;		
	}
	.Background-buildings {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 38em;
		background-image: url('../../../assets/images/background-buildings.png');
		background-size: auto 100%;
		background-repeat: repeat-x;
		background-position: bottom left;
	}

	.Background-car {
		display: none;
		position: absolute;
		bottom: 0;
		left: calc(50% - 8em);
		width: 16em;
		height: 8.5em;
		background-image: url(../../../assets/images/car.svg);

	}

	&.tablet {
		.Background-clouds {
			top: 4.885em;
			height: 9.86em;
		}
	}

	&.pauseScreen {
		.Background-skyline {
			-webkit-animation: displaceSkyline 10s linear infinite;
			animation: displaceSkyline 10s linear infinite;
		}
		.Background-buildings {
			-webkit-animation: displaceBuildings 10s linear infinite;
			animation: displaceBuildings 10s linear infinite;
		}
		.Background-car {
			display: block;
		}
	}

	&.lobby,
	&.assignment,
	&.buildings {
		.Background-buildings {
			display: none;
		}
	}
}

@-webkit-keyframes displaceSkyline {
  from { background-position: left top; }
  to   { background-position: left -51.26em top; }
}

@keyframes displaceSkyline {
  from { background-position: left top; }
  to   { background-position: left -51.26em top; }
}

@-webkit-keyframes displaceBuildings {
	from {background-position: left bottom;}
	to {background-position: left -101.984em bottom}
}
@keyframes displaceBuildings {
	from {background-position: left bottom;}
	to {background-position: left -101.984em bottom}
}
