@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.TaskIntro {
	position: relative;
	width: 100%;
	@include flex('center', 'center', 'column');
	.TaskIntro-title {
		@include flex('center', 'center');
		background-color: $light-blue;
		border-radius: 1em;
		width: 11em;
		height: 1.5em;

		span {
			color: $text-dark;
			font-size: 0.8em;
			font-weight: 500;
		}
	}
	.TaskIntro-text {
		@include flex('center', 'center');
		width: 100%;

		border-style: solid;
		border-width: 0.1em;
		border-color: $light-blue;
		border-radius: 0.4em;
		
		color: $text-dark;
		background-color: $white;
		padding: 1em 1em 1em 3.75em;
		margin-bottom: 1em;
		margin-top: 0.7em;

		p {
			text-align: center;
			font-weight: 500;
			margin-top: 0;
			font-size: 0.85em;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-weight: 900;
			font-size: 0.85em;
		}
	}

	.TaskIntro-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	&.doughScraper {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url(../../../../assets/images/assignments/doughScraper.png);
			background-size: 11em auto;
			background-position: bottom 2em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 13em;
		}
	}

	&.sugar {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url(../../../../assets/images/assignments/sugar.png);
			background-size: 12em auto;
			background-position: bottom 2em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 13em;
		}
	}

	&.lentils {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url(../../../../assets/images/assignments/lentils.jpg);
			background-size: 11em auto;
			background-position: bottom 1.75em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 12em;
		}
	}

	&.keyhole {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url(../../../../assets/images/assignments/keyhole.png);
			background-size: 10em auto;
			background-position: bottom 2em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 13em;
		}
	}

	&.pliers {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url(../../../../assets/images/assignments/pliers.png);
			background-size: 15em auto;
			background-position: bottom 2em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}
}
