@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Admin {
	position: relative;
	width: 100%;
	height: 100%;
	color: $text-dark;
	background-image: 
	linear-gradient(
		to bottom,
		$skyBlue1 20%,
		$skyBlue2 45%,
		$skyBlue3 65%,
		$skyBlue4 75%,
		$skyBlue1 10%
	);
	background-size: 100% 100%;
	padding: 11.5em 3.75em 0 3.75em;

	.Admin-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}

	.Admin-logoutBtn {
		position: absolute;
		left: 3.75em;
		top: 3em;
	}

	.Admin-addGame {
		width: 30em;
		margin-bottom: 3em;
		@include flex('space-between', 'center');
		.Admin-addGameInput {
			input {
				display: block;
				font-size: 1.11em;
				line-height: 1;
				width: 15em;
				height: 2.5em;
				border: 1px solid #2C71CB;
				border-radius: 0.3em;
				padding: 1em;
				background-color: white;
				color: $text-dark;
				font-weight: normal;
				&::placeholder {color: rgba($text-dark, 0.4);}
			}
		}
	}

	.Admin-games {
		.Admin-gamesTitle {
			span {
				font-size: 1.5em;
				text-transform: uppercase;
			}
		}
		.Admin-game {
			span {
				font-size: 1.25em;
			}
		}

	}
}