/* Colors */
$black: #000000;
$white: #ffffff;
$offwhite: #DEEFF4;

$text-dark: #0B2047;
$text-light: #FCFAF0;

$green: #7FB979;
$dark-green: #31A331;
$red: #E53930;
$blue: #2C71CB;
$lightBlue: #DEEFF4;
$light-blue: #BEE0EA;
$darkBlue: #0B2047;

$yellow-complete: #EDA60A;
$yellow-current: #FFC917;

// background gradient
$skyBlue1: #33ACC2;
$skyBlue2: #3FB9CD;
$skyBlue3: #4BC5D7;
$skyBlue4: #4FCADB;