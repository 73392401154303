@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

@mixin animateCorrect() {
  -webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@mixin animateWrong() {
  -webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@mixin animatePow() {
  -webkit-animation: pow 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation: pow 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
}

@mixin fadeIn($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
  @include opacity(0);
  animation: fadeIn $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeIn $seconds #{$timing} $delay forwards;
}

@mixin fadeOut($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
  @include opacity(1);
  animation: fadeOut $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeOut $seconds #{$timing} $delay forwards;
}

@mixin pulse($seconds: '2s') {
  @include opacity(0.75);
  animation: pulse $seconds infinite;
  -webkit-animation: pulse $seconds infinite;
}

@mixin delayButton($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  @include opacity(0);
  pointer-events: none;
  cursor: default;
  animation: delayButton $seconds #{$timing} $delay forwards;
  -webkit-animation: delayButton $seconds #{$timing} $delay forwards;
}

@mixin riseIn($seconds: 2s, $delay: 0s) {
  transform-origin: bottom;
  @include opacity(0);
  animation: riseIn $seconds $delay forwards;
  -webkit-animation: riseIn $seconds $delay forwards;
}

@mixin sinkOut($seconds: 2s, $delay: 0s) {
  @include opacity(1);
  transform-origin: bottom;
  animation: sinkOut $seconds $delay forwards;
  -webkit-animation: sinkOut $seconds $delay forwards;
}

@mixin in-out($seconds: 2s) {
  @include opacity(0);
  transform-origin: bottom center;
  animation: in-out $seconds forwards;
  -webkit-animation: in-out $seconds forwards;
}

@keyframes in-out {
  0% {
    @include opacity(0);
    @include scale(0.25);
  }
  10% {
    @include opacity(1);
    @include scale(1);
  }
  20% {
    @include opacity(1);
    @include scale(1);
  }
  100% {
    @include opacity(0);
    @include scale(0.5);
  } 
}
@-webkit-keyframes in-out {
  0% {
    @include opacity(0);
    @include scale(0.25);
  }
  10% {
    @include opacity(1);
    @include scale(1);
  }
  20% {
    @include opacity(1);
    @include scale(1);
  }
  100% {
    @include opacity(0);
    @include scale(0.5);
  } 
}

@keyframes riseIn {
  0% {
    @include opacity(0);
    @include scaleY(0);
  }
  100% {
    @include opacity(1);
    @include scaleY(1);
  }  
}
@-webkit-keyframes riseIn {
  0% {
    @include opacity(0);
    @include scaleY(0);
  }
  100% {
    @include opacity(1);
    @include scaleY(1);
  }  
}

@keyframes sinkOut {
  0% {
    @include opacity(1);
    @include scaleY(1);
  }
  100% {
    @include opacity(0);
    @include scaleY(0);
  } 
}
@-webkit-keyframes sinkOut {
  0% {
    @include opacity(1);
    @include scaleY(1);
  }
  100% {
    @include opacity(0);
    @include scaleY(0);
  } 
}


@mixin singlePulseColor($seconds: '2s', $delay: 0s) {
  animation: singlePulseColor $seconds $delay forwards;
  -webkit-animation: singlePulseColor $seconds $delay forwards;
}

@keyframes singlePulseColor {
	0% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
  }
  25% {
    background-color: $yellow-current;
    stroke: $yellow-current;
  }
	50% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
  }
  75% {
    background-color: $yellow-current;
    stroke: $yellow-current;
  }
	99% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
  }
  100% {
    background-color: auto;
    stroke: auto;
  }
}
@-webkit-keyframes singlePulseColor {
	0% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
  }
  25% {
    background-color: $yellow-current;
    stroke: $yellow-current;
  }
	50% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
  }
  75% {
    background-color: $yellow-current;
    stroke: $yellow-current;
  }
	90% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
  }
  100% {
    background-color: initial;
    stroke: initial;
  }
}

@mixin pulseScale($seconds: '2s', $iterationCount: infinite, $animationFillMode: forwards, $delay: 0s) {
  animation: pulseScale $seconds $delay $iterationCount $animationFillMode;
  -webkit-animation: pulseScale $seconds $delay $iterationCount $animationFillMode;
}

@mixin pulseColor($seconds: '2s', $delay: '0s', $iterationCount: infinite, $animationFillMode: forwards) {
  animation: pulseColor $seconds $delay $iterationCount $animationFillMode;
  -webkit-animation: pulseColor $seconds $delay $iterationCount $animationFillMode;
}

@mixin pulseColor-timer($seconds: '2s', $iterationCount: infinite, $animationFillMode: forwards) {
  animation: pulseColor-timer $seconds $iterationCount $animationFillMode;
  -webkit-animation: pulseColor-timer $seconds $iterationCount $animationFillMode;
}

@keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;
  }
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;
  }
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}
@-webkit-keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;
  }
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;
  }
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}

@keyframes pulse {
	0% {@include opacity(1);}
  50% {@include opacity(0.1);}
	100% {@include opacity(1);}
}
@-webkit-keyframes pulse {
	0% {@include opacity(1);}
  50% {@include opacity(0.1);}
	100% {@include opacity(1);}
}

@keyframes pulseScale {
	0% {@include scale(1);}
  50% {@include scale(1.5);}
	100% {@include scale(1);}
}
@-webkit-keyframes pulseScale {
	0% {@include scale(1);}
  50% {@include scale(1.5);}
	100% {@include scale(1);}
}

@keyframes pulseColor {
	0% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
    fill: $yellow-complete;
  }
  50% {
    background-color: $yellow-current;
    stroke: $yellow-current;
    fill: $yellow-current;
  }
	100% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
    fill: $yellow-complete;
  }
}
@-webkit-keyframes pulseColor {
	0% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
    fill: $yellow-complete;
  }
  50% {
    background-color: $yellow-current;
    stroke: $yellow-current;
    fill: $yellow-current;
  }
	100% {
    background-color: $yellow-complete;
    stroke: $yellow-complete;
    fill: $yellow-complete;
  }
}
@keyframes pulseColor-timer {
	0% {
    background-color: $red;
    stroke: $red;
  }
  50% {
    background-color: $yellow-current;
    stroke: $yellow-current;
  }
	100% {
    background-color: $red;
    stroke: $red;
  }
}
@-webkit-keyframes pulseColor-timer {
	0% {
    background-color: $red;
    stroke: $red;
  }
  50% {
    background-color: $yellow-current;
    stroke: $yellow-current;
  }
	100% {
    background-color: $red;
    stroke: $red;
  }
}

@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@mixin slideInFromLeft($seconds: 1s) {
	-webkit-animation: slideInFromLeft $seconds linear infinite;
	animation: slideInFromLeft $seconds linear infinite;
}

@keyframes slideInFromLeft {
  from {@include translate(-80vw);}
  to {@include translate(80vw);}
}
@-webkit-keyframes slideInFromLeft {
  from {@include translate(-80vw);}
  to {@include translate(80vw);}
}

@mixin glow($color, $duration: 1s) {
	-webkit-animation: glow $duration linear infinite;
	animation: glow $duration linear infinite;

	@-webkit-keyframes glow {
		0% {
      @include box-shadow(0em, 0em, 2em, 0.25em, $color);
    }
    50% {
      @include box-shadow(0em, 0em, 1em, 0em, rgba($color, 0.5));
    }
		100% {
      @include box-shadow(0em, 0em, 2em, 0.25em, $color);
    }
	}

	@keyframes glow {
		0% {
      @include box-shadow(0em, 0em, 2em, 0.25em, $color);
    }
    50% {
      @include box-shadow(0em, 0em, 1em, 0em, rgba($color, 0.5));
    }
		100% {
      @include box-shadow(0em, 0em, 2em, 0.25em, $color);
    }
	}
}

@mixin animateBuildingSign($duration, $delay, $iterations) {
  -webkit-animation: animateBuildingSign $duration $delay linear $iterations;
	animation: animateBuildingSign $duration $delay linear $iterations;

	@-webkit-keyframes animateBuildingSign {
    0% {
      @include scale(1);
      filter: drop-shadow(0em 0em 0em rgba($white, 0));
    }
    50% {
      @include scale(1.25);
      filter: drop-shadow(0em 0em 0.5em rgba($white, 0.5));
    }
    100% {
      @include scale(1);
      filter: drop-shadow(0em 0em 0em rgba($white, 0));
    }
	}

	@keyframes animateBuildingSign {
    0% {
      @include scale(1);
      filter: drop-shadow(0em 0em 0em rgba($white, 0));
    }
    50% {
      @include scale(1.25);
      filter: drop-shadow(0em 0em 0.5em rgba($white, 0.5));
    }
    100% {
      @include scale(1);
      filter: drop-shadow(0em 0em 0em rgba($white, 0));
    }
	}
}

@mixin buildingDance($duration, $delay, $iterations) {
  -webkit-animation: buildingDance $duration $delay cubic-bezier(0.38, 0.02, 0.4, 0.25) $iterations;
	animation: buildingDance $duration $delay cubic-bezier(0.38, 0.02, 0.4, 0.25) $iterations;

	@-webkit-keyframes buildingDance {
    0% {
      @include scale(1);
    }
    50% {
      @include scale(0.9);
    }
    100% {
      @include scale(1);
    }
	}

	@keyframes buildingDance {
    0% {
      @include scale(1);
    }
    50% {
      @include scale(0.9);
    }
    100% {
      @include scale(1);
    }
	}
}

@mixin blink($seconds: 1s) {
	-webkit-animation: blink $seconds linear infinite;
	animation: blink $seconds linear infinite;
}

@keyframes blink {
  0% {
    @include opacity(0);
  }
  50% {
    @include opacity(0);
  }
  51% {
    @include opacity(1);
  }
  100% {
    @include opacity(1);
  }
}
@-webkit-keyframes blink {
  0% {
    @include opacity(0);
  }
  50% {
    @include opacity(0);
  }
  51% {
    @include opacity(1);
  }
  100% {
    @include opacity(1);
  }
}

@mixin flyBy($seconds: 1s) {
	-webkit-animation: flyBy $seconds ease-in-out infinite;
	animation: flyBy $seconds ease-in-out infinite;
}

@keyframes flyBy {
  0% {
    @include translate(0, 1em);
  }
  50% {
    @include translate(0, -1em);
  }
  100% {
    @include translate(0, 1em);
  }
}
@-webkit-keyframes flyBy {
  0% {
    @include translate(0, 1em);
  }
  50% {
    @include translate(0, -1em);
  }
  100% {
    @include translate(0, 1em);
  }
}

@mixin radialGlow($duration, $delay) {
  -webkit-animation: radialGlow $duration $delay linear infinite;
	animation: radialGlow $duration $delay linear infinite;
  background-image: radial-gradient(50% 50%, rgba($white, 1) 30%, rgba($white, 0.75), rgba($white, 0));

	@-webkit-keyframes radialGlow {
    0% {
      @include scale(0.7);
    }
    50% {
      @include scale(1);
    }
    100% {
      @include scale(0.7);
    }
	}

	@keyframes radialGlow {
    0% {
      @include scale(0.7);
    }
    50% {
      @include scale(1);
    }
    100% {
      @include scale(0.7);
    }
	}
}