// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance: #{$value};
	appearance: #{$value};
}

// TOUCH ACTION
@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

@mixin placeholderColor($color) {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $color;
		opacity: 1; /* Firefox */
	}
	
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $color;
	}
	
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $color;
	}
}

/* Autofill state for inputs */
@mixin autofilled($color, $bgColor) {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
	animation-name: autofill;
	animation-fill-mode: both;
	@keyframes autofill {
		to {
			color: $color;
			background-color: $bgColor;
		}
	}
}

// FLEX
@mixin flex($justify: '', $align: '', $direction: '') {
	display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box; // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox; // TWEENER - IE 10
	display: -webkit-flex; // NEW - Chrome
	display: flex; // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify: '', $align: '', $direction: '') {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	@include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify != '' {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align != '' {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};
	}
	@if $direction != '' {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
	align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

// SCALE
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

@mixin scaleX($scale) {
	-ms-transform: scaleX($scale); /* IE 9 */
	-webkit-transform: scaleX($scale); /* Safari */
	transform: scaleX($scale);
}
@mixin scaleY($scale) {
	-ms-transform: scaleY($scale); /* IE 9 */
	-webkit-transform: scaleY($scale); /* Safari */
	transform: scaleY($scale);
}

// ROTATE
@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

// TRANSITION
@mixin transition($seconds: 1s, $target: 'all', $type: 'linear') {
	-webkit-transition: #{$target} $seconds #{$type}; /* WebKit */
	-moz-transition: #{$target} $seconds #{$type}; /* Firefox */
	-o-transition: #{$target} $seconds #{$type}; /* Opera */
	-ms-transition: #{$target} $seconds #{$type}; /* MS */
	transition: #{$target} $seconds #{$type}; /* Standard */
}

@mixin transitionTransform($seconds, $type) {
	-webkit-transition: -webkit-transform $seconds #{$type}; /* WebKit */
	-moz-transition: -moz-transform $seconds #{$type}; /* Firefox */
	-o-transition: -o-transform $seconds #{$type}; /* Opera */
	-ms-transition: -ms-transform $seconds #{$type}; /* MS */
	transition: transform $seconds #{$type}; /* Standard */
}


@mixin transition-delay($time) {
	-webkit-transition-delay: $time;
	-moz-transition-delay: $time;
	-o-transition-delay: $time;
	transition-delay: $time;
}

@mixin animationTimingFunction($type) {
	-webkit-animation-timing-function: #{$type};
	animation-timing-function: #{$type};
}

@mixin translate($x, $y: 0) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translate3d($x: 0, $y: 0, $z: 0) {
	-ms-transform: translate3d($x, $y, $z);
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin translateAndRotate($x: 0, $y: 0, $z: 0, $angle: 0) {
	-ms-transform: translate3d($x, $y, $z) rotate($angle);
	-webkit-transform: translate3d($x, $y, $z) rotate($angle);
	transform: translate3d($x, $y, $z) rotate($angle);
}

@mixin translateAndScale($x: 0, $y: 0, $scale: 1) {
	-webkit-transform: translate($x, $y) scale($scale);
	-moz-transform: translate($x, $y) scale($scale);
	-ms-transform: translate($x, $y) scale($scale);
	-o-transform: translate($x, $y) scale($scale);
	transform: translate($x, $y) scale($scale);
}

@mixin origin($x, $y) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

@mixin flip($x: 1, $y: 1) {
	-moz-transform: scaleX($x) scaleY($y);
	-o-transform: scaleX($x) scaleY($y);
	-webkit-transform: scaleX($x) scaleY($y);
	transform: scaleX($x) scaleY($y);
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity='
		$procentOpacity ')'; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// INPUT
@mixin input-placeholder($color) {
	input::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	input:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	input::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	input:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// TEXTAREA
@mixin textarea-placeholder($color) {
	textarea::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	textarea:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	textarea::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	textarea:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently*/
}

// SCROLLBARS
/* HIde scrollbar */
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

// CUSTOM SCROLLBAR
@mixin custom-scrollbar($width, $backgroundColor, $thumbColor) {
	/*Chrome, Safari and Edge*/
	&::-webkit-scrollbar {

		width: $width; /* width of the veritcal scrollbar */
		height: $width; /* width of the horizontal scrollbar */
	}
	&::-webkit-scrollbar-track {
		border-radius: $width;
		background-color: $backgroundColor;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: $width;
		border: 1px solid $backgroundColor;
	}
	&::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
	/*Firefox*/
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $backgroundColor;
}

@mixin rotationStyle($style) {
	-webkit-transform-style: #{$style};
	-moz-transform-style: #{$style};
	transform-style: #{$style};
}

// BOX SHADOW
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

@mixin box-inner-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color inset;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color inset;
	box-shadow: $hoffset $voffset $blur $spread $color inset;
}

@mixin box-shadow-inset-outset(
	$hoffset1, $voffset1, $blur1, $spread1, $color1,
	$hoffset2, $voffset2, $blur2, $spread2, $color2,
) {
	-webkit-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2;
	-moz-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2;
	box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2;
}

@mixin box-shadow-inset-outset-outset(
	$hoffset1, $voffset1, $blur1, $spread1, $color1,
	$hoffset2, $voffset2, $blur2, $spread2, $color2,
	$hoffset3, $voffset3, $blur3, $spread3, $color3
) {
	-webkit-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2, 
		$hoffset3 $voffset3 $blur3 $spread3 $color3;
	-moz-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2, 
		$hoffset3 $voffset3 $blur3 $spread3 $color3;
	box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2, 
		$hoffset3 $voffset3 $blur3 $spread3 $color3;
}

@mixin blurBackground($blurPixels) {
	-webkit-backdrop-filter: blur($blurPixels);
	backdrop-filter: blur($blurPixels);
}

@mixin rotate3D($x, $y, $z, $speed) {
	-webkit-animation: rotate3D $speed linear infinite;
	animation: rotate3D $speed linear infinite;

	@-webkit-keyframes rotate3D {
		0% {-webkit-transform: rotate3d($x, $y, $z, 0deg);}
		50% {-webkit-transform: rotate3d($x, $y, $z, 180deg);}
		100% {-webkit-transform: rotate3d($x, $y, $z, 360deg);}
	}
	
	@keyframes rotate3D {
		0% {transform: rotate3d($x, $y, $z, 0deg);}
		50% {transform: rotate3d($x, $y, $z, 180deg);}
		100% {transform: rotate3d($x, $y, $z, 360deg);}
	}
}

@mixin fall($from, $to, $duration) {
	-webkit-animation: fall linear infinite;
	animation: fall linear infinite;

	@-webkit-keyframes fall {
		0% {-webkit-transform: translate3d(0, $from, 0);}
		100% {-webkit-transform: translate3d(0, $to, 0);}
	}

	@keyframes fall {
		0% {transform: translate3d(0, $from, 0);}
		100% {transform: translate3d(0, $to, 0);}
	}
}