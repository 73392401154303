@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	display: inline-block;
	text-align: center;
	color: $text-dark;
	background-color: $white;
	padding: 0.1em 0.5em;
	border-radius: 0.125em;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		text-transform: uppercase;
		text-decoration: none;
		font-weight: bold;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: darken($white, 5%);
	}
	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 3em;
			height: 100%;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
		}
	}

	&.login {
		width: 100%;
		height: 2.56em;
		border-radius: 0.56em;
		color: $white;
		background-color: $blue;
		span {
			font-weight: 500;
			font-size: 1.11em;
		}
	}

	&.flatBlue {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		border-radius: 0.5em;
		background-color: $blue;
		color: $text-light;
		width: 100%;
		height: 100%;
	}

	&.lowOpacity {
		@include opacity(0.5);

		span {
			font-size: 0.65em;
		}

		&:hover {
			@include opacity(1);
		}
	}

	&.multipleChoice {
		width: 100%;
		background-color: $blue;
		min-height: 2.25em;
		line-height: 1.25em;
		border-radius: 0.55em;
		font-weight: bold;
		text-align: center;
		padding: 0.75em 1.25em 0.5em 1.25em;
		margin-bottom: 0.8em;
		
		&.disabled {
			opacity: 1;
			cursor: not-allowed;
			&:hover,
			&:focus,
			&:active {
				opacity: 1;
			}
		}

		span {
			text-transform: none;
			color: $text-light;
		}

		cursor: pointer;
		@include flex('center', 'center');
		@include no-select();

		&.correct,
		&.animateCorrect {
			background-color: $dark-green;
		}

		&.wrong,
		&.animateWrong {
			background-color: $red;
		}

		&.animateCorrect {@include animateCorrect();}
		&.animateWrong {@include animateWrong();}
	}
}