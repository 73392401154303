@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Points {
	position: relative;
	height: 5.725em;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: 0.22em;
	.Points-icon {
		position: relative;
		z-index: 4;

		&.trophy {
			position: absolute;
			top: 0;
			right: 0;
			height: 2.9905em;
			width: calc(3em*2);
			background-image: url(../../../assets/images/icons/icon-trophy.svg);
			background-size: 3em 2.9905em;
			background-position: right 0.5em top 0em;

			&.pulseScale {
				@include pulseScale(0.5s, 2);
			}
		}
		&.player-1,
		&.player-2 {
			position: absolute;
			top: 0;
			right: 0;
			height: 2.45em;
			width: calc(2*1.65em);
			background-image: url(../../../assets/images/icons/icon-user-one.svg);
			background-size: 1.65em 2.45em;
			background-position: right 1.175em top 0em;
		
			&.pulseScale {
				@include pulseScale(0.5s, 2);
			}
		}
		&.player-2 {
			background-image: url(../../../assets/images/icons/icon-user-two.svg);
		}
	}

	.Points-wrapper {
		width: 100%;

		.Points-window {
			position: relative;
			z-index: 3;
			width: 100%;
			height: 4.85em;
			background-color: $white;
			border-radius: 0.5em;
			padding-left: 1.025em;
			padding-top: 0.57em;
			padding: 0.5em;
			@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba(black, 0.2));
			span {
				font-weight: bold;
				font-style: normal;
				color: $text-dark;
				text-transform: uppercase;
				font-size: 0.9em;
			}
			.Points-number {
				height: 2em;
				width: 100%;
				background-color: $light-blue;
				border-radius: 0.25em;
				margin-top: 0.52em;
				text-align: right;
				padding-right: 0.7em;
				span {
					font-size: 1.6em;
					font-weight: 900;
					font-style: normal;
					color: $text-dark;
				}
			}
		}
		.Points-streakIndicatorWrapper {
			position: absolute;
			bottom: 0;
			z-index: 4;

			&.pulseScale {
				@include pulseScale(0.5s, 2);
			}
			
			.Points-streakIndicator {
				position: relative;
				z-index: 4;
				margin-left: 0.25em;
				margin-bottom: 0.25em;
				width: 2.4em;
				height: 2.8em;

				&.streak2 {
					background-image: url(../../../assets/images/icons/icon-streak1.svg);
				}
				&.streak3 {
					background-image: url(../../../assets/images/icons/icon-streak2.svg);
				}
				&.streak4 {
					background-image: url(../../../assets/images/icons/icon-streak3.svg);
				}
			}

			.Points-streakNumber {
				position: absolute;
				z-index: 4;
				bottom: 1.2em;
				left: 1em;
				span {
					color: $white;
					font-size: 1.2em;
					font-weight: bold;
				}
			}
		}

		.Points-streakMarkerWrapper {
			position: absolute;
			width: 4.2em;
			height: 4.85em;
			bottom: 0em;
			left: calc(50% - 2.1em);
			@include transition(0.25s, 'bottom');

			&.pulseScale {
				bottom: 5.35em;
				@include pulseScale(0.5s, 2, $delay: 0.25s);
			}

			.Points-streakMarker {
				position: relative;
				z-index: 2;
				background-size: contain;
				width: 100%;
				height: 100%;
				border-radius: 2.1em;

				&.hasStreak {
					@include glow($white);
				}

				&.streak2 {
					background-image: url(../../../assets/images/icons/icon-streak-large1.svg);
				}
				&.streak3 {
					background-image: url(../../../assets/images/icons/icon-streak-large2.svg);
				}
				&.streak4 {
					background-image: url(../../../assets/images/icons/icon-streak-large3.svg);
				}
			}
		}
	}
}