@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	position: relative;
	width: 100%;
	height: 100%;

	&.tablet {
		font-size: calc(20 * ((100vw / 1025)));
	}
	
}