@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Building {
	@include flex('center');
	width: 100%;

	.Building-dustCloud {
		position: absolute;
		bottom: -1em;
		background-image: url(../../../assets/images/dust-cloud.svg);
		background-size: contain;
		width: 14.85em;
		height: 7.7em;
		@include in-out(4s);
	}

	.Building-rankBuilding {
		position: absolute;
		@include flex('center');
		bottom: 0em;

		svg {
			position: absolute;
			height: 100%;
			width: 100%;
		}

		.Building-playerName {
			position: absolute;
			bottom: 2.6em;
			span {
				color: $text-dark;
				font-size: 0.79em;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
		&.current {
			@include riseIn(1s, 0.5s);
		}
		&.previous {
			@include sinkOut(1s);
		}
		
		&.rank-0 {
			width: 10.95em;
			height: 5.6em;
		}
		&.rank-1 {
			width: 10.95em;
			height: 7.616em;
		}
		&.rank-2 {
			width: 10.41em;
			height: 10.48em;
		}
		&.rank-3 {
			width: 11.58em;
			height: 16.35em;
		}
		&.rank-4 {
			width: 11.58em;
			height: 21.7em;
		}
		&.rank-5 {
			width: 11.99em;
			height: 27.1em;
		}
		&.rank-6 {
			width: 12.04em;
			height: 32.54em;
		}
		&.rank-7 {
			width: 14.86em;
			height: 37.97em;
		}

		.Building-buildingSvg {
			&.playAnimation {
				#Rank {
					@include animateBuildingSign(0.5s, 1.5s, 3);
					transform-origin: top;

					:nth-child(1) {
						@include pulseColor(0.5s, 1.5s, 3);
					}
				}
			}
		}
	}
}