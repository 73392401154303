@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.NamePlate {
	@include flex('flex-start','flex-end','row');
	position: relative;

	.NamePlate-background {
		@include flex('flex-start','center','row');
		border-radius: 0.5em;
		background-color: $white;
		width: 9em;
		height: 2.4em;
		padding-left: 1em;

		@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba(black, 0.2));

		span {
			color: $text-dark;
			font-weight: bold;
			font-size: 0.9em;
		}

	}

	.NamePlate-userIcon {
		position: absolute;
		right: 0em;
		
		width: 1.65em;
		height: 2.45em;
		margin-right: 0.45em;
		margin-bottom: 0.73em;
		&.NamePlate-userIcon-2 {
			background-image: url(../../../assets/images/icons/icon-user-two.svg);
		}
		&.NamePlate-userIcon-1 {
			background-image: url(../../../assets/images/icons/icon-user-one.svg);
		}
	}
}
