@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Buildings {
	@include flex('space-between');
	position: relative;
	height: 100%;
	width: 100%;
	
	.Buildings-sidebar {
		z-index: 3;
		margin-top: 1.22em;
		margin-left: 1.2em;
		.Buildings-logo {
			height: 4.21em;
			width: 9.5255em;
		}

		.Buildings-resetButton {
			margin-top: 0.75em;
			height: 1.25em;
			width: 3.6em;
		}
	}

	.Buildings-points {
		position: relative;
		&.player-2,
		&.player-1 {
			width: 9em;
			position: absolute;
			bottom: 1.5em;
		}
		&.player-1 {
			left: 1.5em;
		}
		&.player-2 {
			right: 1.5em;
		}
	}

	.Buildings-buildingsWrapper {
		@include flex();
		position: relative;
		z-index: 5;
		height: 100%;
		
		.Buildings-rankBuilding {
			position: relative;
			width: 17em;
			transform-origin: bottom;

			&.animated {
				@include buildingDance(0.625s, 0s, 16);
			}
		}
	}

	.Buildings-highscore {
		position: absolute;
		top: 1.22em;
		right: 1.5em;
	}
	.Buildings-spacer {
		width: 9.5em;
	}
}