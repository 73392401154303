@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Lobby {
	@include flex('center','center');
	height: 100%;
	width: 100%;
	
	.Lobby-logo {
		position: absolute;
		top: 1.835em;
		left: calc(50% - 0.5 * 13.8em);
		width: 13.8em;
		height: 6.1em;

		&.ready {
			top: 1.2em;
			left: 1.2em;
			width: 9.5em;
			height: 4.2em;
		}
	}

	.Lobby-name {
		position: absolute;
		left: 1.5em;
		top: 6.35em;
	}

	.Lobby-nameChoice {
		position: relative;
		@include flex('','center','column');
		@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba(black, 0.2));
		width: 24.4em;
		height: 17.4em;
		margin-top: 3em;
		background-color: $white;
		border-radius: 0.5em;

		.Lobby-gameGuide {
			padding-top: 1.5em;
			margin-bottom: 1em;
			padding-left: 1em;
			padding-right: 1em;
			text-align: center;
			span {
				color: $text-dark;
				font-weight: 500;
			}
		}

		.Lobby-waitingForPlayerTwo {
			@include flex('center','center','column');
			width: 100%;
			
			.Lobby-player {
				@include flex('flex-start','flex-end','row');
				position: relative;
				border: solid;
				border-color: $light-blue;
				border-radius: 0.5em;
				border-width: 0.05em;
				padding: 0.5em;
				padding-left: 0.85em;

				width: 8.85em;
				height: 2.4em;

				margin-bottom: 1.39em;

				&.isReadyPlayer1,
				&.isReadyPlayer2 {
					border-width: 0.15em;
				}
				
				&.isReadyPlayer1 {
					border-color: $blue;
				}

				&.isReadyPlayer2 {
					border-color: $green;
				}


				.Lobby-playerName {

					span {
						color: $text-dark;
						text-transform: uppercase;
						font-weight: bold;
					}
				}

				.Lobby-userIcon {
					position: absolute;
					right: 0em;
					width: 1.5em;
					height: 2.5em;
					margin-right: 0.45em;
					&.Lobby-userIcon-1 {
						background-image: url(../../../../assets/images/icons/icon-user-one.svg);
					}
					&.Lobby-userIcon-2 {
						background-image: url(../../../../assets/images/icons/icon-user-two.svg);
					}
					&.noName {
						background-image: url(../../../../assets/images/icons/icon-user-inactive.svg);
					}
				}
			}

			.Lobby-timeLeft {
				height: 1em;
				span {
					color: $text-dark;
					font-weight: bold;
				}
			}

			.Lobby-readyButton {
				width: 11.5em;
			}
		}


		.Lobby-inputWrapper {
			@include flex('','','column');
			width: 11.5em;

			.Lobby-input {
				background-color: $offwhite;
				padding: 0.5em;
				border-radius: 0.5em;
			}
			
			.Lobby-title {
				@include flex('space-between','flex-end','row');
				margin-bottom: 1.055em;
				span {
					color: $text-dark;
					text-transform: uppercase;
					font-weight: bold;
				}
				.Lobby-userIcon {
					width: 1.5em;
					height: 2.5em;
					margin-right: 0.45em;
					&.Lobby-userIcon-1 {
						background-image: url(../../../../assets/images/icons/icon-user-one.svg);
					}
					&.Lobby-userIcon-2 {
						background-image: url(../../../../assets/images/icons/icon-user-two.svg);
					}
				}
				
			}

			.Lobby-startButton {
				margin-top: 1.18em;
			}
		}
	}
}