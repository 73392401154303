@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ConfettiCannon {
	position: absolute;
	z-index: 99;
	height: 100%;
	width: 100%;
    pointer-events: none;

    .ConfettiCannon-confettiWrapper {
        .ConfettiCannon-fallingConfetti {
            position: absolute;
            pointer-events: none;
            top: -2.5em;
            @include fall(-2.5em, 100vh, 5);
            .ConfettiCannon-rotatingConfetti {
                width: 0.3em;
                height: 1em;
                @include rotate3D(0, 1, 0.3, 0.5s);
            }
        }
    }
}