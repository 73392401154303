@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Assignments {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 1.21em;

	@include flex('center', 'flex-start', 'row');

	.Assignments-sideWrapper {
		position: relative;
		&.left {
			width: 9.5em;
		}
		&.right {
			width: 12.3em;	
		}
		
		.Assignments-logo {
			position: relative;
			width: 100%;
			height: 4.21em;
			margin-bottom: 0.17em;
		}

		.Assignments-displayNameWrapper {
			position: relative;
			height: 3.18em;
			width: 100%;

			.Assignments-displayName {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 2.4em;				
				background-color: $white;
				border-radius: 0.5em;
				padding-left: 1em;
				padding-top: 0.57em;
				span {
					font-size: 0.9em;
					font-weight: 700;
					color: $text-dark;
					text-transform: uppercase;
				}
			}
			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				height: 2.45em;
				width: calc(2 * 1.65em);
				background-size: 1.65em 2.45em;
				background-position: right 0.73em top 0em;
			}
			&.Assignments-displayNameWrapper-1::after {
				background-image: url(../../../../assets/images/icons/icon-user-one.svg);
			}
			&.Assignments-displayNameWrapper-2::after {
				background-image: url(../../../../assets/images/icons/icon-user-two.svg);
			}
			&.pulseScale {
				&::after{
					@include pulseScale(2s);
				}
			}
		}

		.Assignments-points {
			margin-bottom: 0.37em;
		}

		.Assignments-rankWrapper {
			position: relative;

			.Assignments-crownIcon {
				position: absolute;
				top: -0.9em;
				right: 1em;
				height: 2.72em;
				width: 3.26em;
				background-image: url(../../../../assets/images/icons/icon-crown.svg);
				background-size: contain;

				&.pulseScale {
					@include pulseScale(0.5s, 2);
				}
			}

			.Assignments-rank {
				position: relative;
				width: 100%;
				height: 25em;
				background-color: $white;
				border-radius: 0.5em;
				padding-left: 1em;
				padding-top: 0.57em;
				span {
					font-weight: 700;
					font-style: normal;
					color: $text-dark;
					font-size: 0.9em;
					text-transform: uppercase;
				}
				.Assignments-rankProgressbarWrapper {
					width: 11em;
					height: 21.4em;
					margin-left: -0.38em;
					margin-top: 0.79em;
					@include flex('center', 'center', 'column');
					

					.Assignments-rankProgressbar {
						position: relative;
						width: 100%;
						height: 2.85em;
						margin-bottom: 0.23em;
						background-color: rgb($light-blue, 0.5);
						@include flex('flex-start', 'center');

						.Assignments-emptyBar {
							background-color: $light-blue;
							width: 1.6em;
							height: 100%;
						}
						
						.Assignments-completionIndicator {
							position: absolute;
							bottom: 0;
							left: 0;
							width: 1.6em;
							height: 0%;
							@include transition(0.5s, 'height');
							&.complete {
								height: 100%;
								background-color: $yellow-complete;
							}
							&.current {
								height: 100%;
								background-color: $yellow-complete;
								&.pulseScale {
									@include pulseColor(0.5s, 0.5s, 3);
								}
							}
						}
						
						div:nth-child(2) {
							margin-left: 0.7em;
						}

					}
					.Assignments-rankProgressbar:first-child {
						border-top-left-radius: 0.25em;
						div:last-child,
						div:first-child {
							border-top-left-radius: 0.25em;
						}
					}
					.Assignments-rankProgressbar:last-child {
						border-bottom-left-radius: 0.25em;
						div:last-child,
						div:first-child {
							border-bottom-left-radius: 0.25em;
						}
					}
				}
			}
		}
	}

	.Assignments-taskWindow {
		position: relative;
		height: 33.5em;
		width: 24.42em;
		background-color: $white;
		border-radius: 0.8em;
		margin-left: 1.19em;
		margin-right: 1.19em;

		.Assignments-task {
			width: 100%;
			height: 100%;
			.Assignment-streakMarker {
				position: absolute;
				z-index: 2;
				background-size: contain;
				width: 4.2em;
				height: 4.85em;
				border-radius: 2.1em;
				top: 0.5em;
				left: 0.8em;

				@include pulseScale(0.5s, 2);
				&.streak2 {
					background-image: url(../../../../assets/images/icons/icon-streak-large1.svg);
				}
				&.streak3 {
					background-image: url(../../../../assets/images/icons/icon-streak-large2.svg);
				}
				&.streak4 {
					background-image: url(../../../../assets/images/icons/icon-streak-large3.svg);
				}
			}
		}

		.Assignments-noMoreTaskPopup {
			width: 100%;
			height: 100%;
			@include flex('center', 'center');

			span {
				color: $text-dark;
				text-align: center;
				font-size: 2em;
				font-weight: bold;
			}
		}
	}
}