@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Highscore {
	background-color: $white;
	border-radius: 0.5em;
	width: 22.32em;
	padding: 0.7em;
	padding-top: 0em;
	@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba(black, 0.2));
	margin-bottom: 0.75em;

	&.isSmall {
		width: 12em;
	}

	.Highscore-header {
		@include flex('', 'flex-end', 'row');
		position: relative;
		padding-top: 0.5em;
		padding-bottom: 0.6em;
		.Highscore-title {
			margin-right: 3.5em;
			span {
				color: $text-dark;
				text-transform: uppercase;
				font-size: 0.9em;
				font-weight: bold;
			}
		}
		.Highscore-trophyIcon {
			position: absolute;
			right: 0;
			background-image: url(../../../assets/images/icons/icon-trophy.svg);
			width: 3em;
			height: 3em;
		}
	}

	.Highscore-list {		
		.Highscore-item:first-of-type {
			background-color: $dark-green;

			span {
				color: $text-light;
				font-weight: 900;
			}
		}

		.Highscore-item {
			@include flex('space-between', 'flex-end');
			background-color: $light-blue;
			color: $text-dark;
			margin-bottom: 0.15em;
			padding-bottom: 0.22em;
			height: 1.8em;

			span {
				text-transform: uppercase;
				font-weight: 500;
				font-size: 0.8em;
			}
			.Highscore-placingWrapper {
				@include flex('', '', 'row');
				.Highscore-placing {
					margin-left: 0.8em;
					margin-right: 0.5em;
					width: 1em;
				}
				.Highscore-name {
					width: 12em;

					&.isSmall {
						width: 6em;
					}
				}
			}
			.Highscore-score {
				@include flex('flex-end', 'center');
				margin-right: 0.5em;
				width: 2.2em;
			}
		}
	}			
}