@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	@include flex('', 'center', 'column');
		
	.MultipleChoice-intro {
		position: relative;
		width: calc(100% - 2em);
		margin: 0.75em 1em 0em 1em;
	}

	.MultipleChoice-options {
		position: relative;
		width: 20.5em;
		left: calc(50% - 10.25em);
		margin: 0.75em auto auto auto;
		padding: 0 1.5em;
		overflow: auto;
		@include hide-scrollbar();
		.MultipleChoice-optionsWrap {
			position: relative;			
			.MultipleChoice-option {
				position: relative;
				width: 100%;
			}
		}	
	}

	&.images {
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					border: none;
					margin: 0;
					padding: 0;
					border-radius: 0;
					@include box-shadow(0, 0, 0, 0, transparent);
					span {
						display: none;
						&:nth-child(2) {
							position: absolute;
							bottom: 0.25em;
							left: 0.25em;
							width: 1.5em;
							height: 1.5em;
						}
					}
				}
			}
		}
	}
}