@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameOver {
	@include flex('center', 'center');
	z-index: 50;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba($darkBlue, 0.6);

	.GameOver-infoWrapper {
		@include flex('center', 'center', 'column');
		position: relative;
		background-color: $white;
		border-radius: 0.5em;
		height: 15.85em;
		width: 24.4em;

		.GameOver-trophyIcon {
			position: absolute;
			top: -1.45em;
			height: 4.5em;
			width: 4.5em;
			background-image: url(../../../assets/images/icons/icon-trophy.svg);
			background-size: contain;
		}
		.GameOver-winner {
			margin-bottom: 1.85em;
			span {
				color: $text-dark;
				font-size: 2em;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		.GameOver-gameResetMessage {
			margin-bottom: 1.1em;
			
			span {
				color: $text-dark;
				font-weight: 500;
			}
		}
		.GameOver-resetButton {
			width: 11.5em;
		}
	}
}