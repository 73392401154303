@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.PauseScreen {
	@include flex('center', 'center');
	position: relative;
	height: 100%;
	width: 100%;

	.PauseScreen-navigation {
		position: absolute;
		left: 0.85em;
		top: 3em;
		height: 1.5em;
		width: 4.3em;
		
		span {
			font-size: 0.75em;
		}
	}
	
	.PauseScreen-resetButton {
		position: absolute;
		left: 0.85em;
		top: 0.7em;
		height: 1.5em;
		width: 4.3em;
				
		span {
			font-size: 0.75em;
		}
	}
	
	.PauseScreen-airplaneWrapper {
		position: absolute;
		top: 8em;
		z-index: 3;
		@include slideInFromLeft(10s);

		.PauseScreen-airplaneMovement {
			position: relative;

			@include flyBy(4s);
			.PauseScreen-airplane {
				background-image: url(../../../../assets/images/airplane.svg);
				background-size: contain;
				width: 31.45em;
				height: 4.53em;
			}

			.PauseScreen-airplaneText {
				@include flex('center');
				position: absolute;
				top: 1.2em;
				width: 23em;

				span {
					font-size: 1.1em;
					font-weight: bold;
					color: $text-dark;
				}
			}
		}
	}
	
	.PauseScreen-logo {
		position: absolute;
		top: 1.8em;
		left: calc(50% - 13.8em * 0.5);
		width: 13.8em;
		height: 6.1em;
	}

	.PauseScreen-countdown {
		background-color: $white;
		width: 24.4em;
		height: 17.35em;
		border-radius: 0.5em;
		color: $text-dark;
		@include flex('center', 'center', 'column');
		@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba($black, 0.2));

		.PauseScreen-gameStart {
			span {
				font-size: 1.9em;
				font-weight: bold;
			}
		}
		.PauseScreen-countDownNumber {
			span {
				font-size: 8.5em;
				font-weight: 900;
				line-height: 1em;
			}
		}
	}

	.PauseScreen-player {
		position: absolute;
		bottom: 1.5em;
		background-color: $white;
		color: $text-dark;
		border-radius: 0.5em;
		width: 13.2em;
		height: 5.2em;
		@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba($black, 0.2));
		
		&.player1 {
			left: 1.5em;
		}
		&.player2 {
			right: 1.5em;
		}

		.PauseScreen-playerHeader {
			margin-left: 1.5em;
			margin-top: 0.38em;
			margin-bottom: 0.5em;

			span {
				font-weight: bold;
			}
		}

		.PauseScreen-playerIconWrapper {
			position: absolute;
			right: 1em;
			top: -0.8em;

			&.readyAnimate {
				@include pulseScale(0.5s, 2);
			}
			
			.PauseScreen-playerIcon {
				width: 1.65em;
				height: 2.45em;
				background-size: contain;

				&.animate {
					@include pulseScale(0.5s, 2);
				}
				&.player1 {
					background-image: url(../../../../assets/images/icons/icon-user-one.svg);
				}
				&.player2 {
					background-image: url(../../../../assets/images/icons/icon-user-two.svg);
				}
			}
		}

		.PauseScreen-playerName {
			@include flex('', 'center');
			width: 11.5em;
			height: 2.3em;
			background-color: $lightBlue;
			border-width: 0.15em;
			border-radius: 0.5em;
			padding: 0.7em;
			margin-left: 0.9em;
			
			&.noInput {
				span {
					padding-left: 0.3em;
					color: rgba($text-dark, 0.3);
				}
			}
			&.player1 {
				&.isReady {
					border-style: solid;
					border-color: $blue;
				}
			}
			&.player2 {
				&.isReady {
					border-style: solid;
					border-color: $green;
				}
			}

			span {
				font-weight: bold;
			}

			.PauseScreen-textCursor {
				position: absolute;
				left: 1.5em;
				margin-bottom: 0.2em;
				@include blink(1s);

				span {
					padding-left: 0;
					font-size: 1.25em;
					color: $text-dark;
				}
			}
		}
	}

	.PauseScreen-fullscreenToggle {
		position: absolute;
		cursor: pointer;
		z-index: 2;
		top: 0.75em;
		right: 0.75em;
		background-image: url(../../../../assets/images/icons/icon-fullscreen.svg);
		background-size: contain;
		background-color: $blue;
		width: 1.5em;
		height: 1.5em;

		border-radius: 0.3em;
		padding: 0.3em;

		&.fullScreen {
			background-image: url(../../../../assets/images/icons/icon-minimize.svg);
		}
	}

	.PauseScreen-muteToggle {
		position: absolute;
		cursor: pointer;
		z-index: 2;
		top: 0.75em;
		right: 2.8em;
		background-image: url(../../../../assets/images/icons/icon-unmuted.svg);
		background-size: contain;
		background-color: $blue;
		width: 1.5em;
		height: 1.5em;

		border-radius: 0.3em;
		padding: 0.3em;

		&.mute {
			background-image: url(../../../../assets/images/icons/icon-muted.svg);
		}
	}
}
