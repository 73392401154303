@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameOverProjector {
	@include flex('center', 'center');
	z-index: 50;
	position: absolute;
	width: 100%;
	height: 100%;

	.GameOverProjector-infoWrapper {
		@include flex('center', 'center', 'column');
		position: relative;

		.GameOverProjector-trophyWrapper {
			@include flex('center', 'center');
			margin-bottom: 1em;
			.GameOverProjector-trophyGlow {
				position: absolute;
				height: 9em;
				width: 9em;
				@include radialGlow(2s, 0s);
			}
			.GameOverProjector-trophyIcon {
				position: relative;
				height: 4.5em;
				width: 4.5em;
				background-image: url(../../../assets/images/icons/icon-trophy.svg);
				background-size: contain;
			}
		}
		.GameOverProjector-messageWrapper 
		{
			@include flex('center', 'center', 'column');
			z-index: 2;

			.GameOverProjector-glow {
				z-index: 2;
				position: absolute;
				width: 100%;
				height: 100%;
				@include radialGlow(2s, 0s);
			}

			.GameOverProjector-winner {
				z-index: 3;
				margin-right: 7em;
				margin-left: 7em;
				span {
					color: $text-dark;
					font-size: 2em;
					font-weight: 900;
					text-transform: uppercase;
				}
			}
			.GameOverProjector-gameResetMessage {
				z-index: 3;
				margin-bottom: 1.1em;
				
				span {
					color: $text-dark;
					font-weight: bold;
				}
			}
		}
	}
}