@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	overflow-x: hidden;
	overflow-y: hidden;
	
	@include flex('space-between', '', 'column');
	height: 100%;
	width: 100%;
	position: relative;
	text-align: center;
	padding: 1em;

	.Settings-cphGamelabLogo {
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-image: url(../../assets/images/logo-cphgamelab.svg);
		right: 2em;
		top: 1.8em;

		width: 8em;
		height: 1.5em;
	}

	.Settings-unordLogo {
		position: absolute;
		z-index: 2;
		background-image: url(../../assets/images/logo-unord.svg);
		left: 2em;
		top: 1.8em;

		width: 6em;
		height: 1em;
	}

	.Settings-logo {
		position: absolute;
		top: 2em;
		left: calc(50% - 0.5 * 15.33em);
		width: 15.33em;
		height: 6.78em;
	}

	.Settings-background {
		position: absolute;
		overflow: hidden;
		z-index: 1;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		.Settings-backgroundSvg {
			height: auto;
			width: 140%;
			@include translate(-25em, 23em);
		}
	}

	.Settings-titleWrapper
	{
		@include flex('center', '', 'row');
		width: 100%;
		margin-top: 10.04em;
		.Settings-title
		{
			z-index: 2;
			span {
				color: black;
				font-size: 1.11em;
				font-weight: 500;
			}
			
		}
	}

	.Settings-dialogueWrapper
	{
		@include flex('center', '', 'row');
		z-index: 2;
		width: 100%;
		height: 100%;
		margin-top: 1.66em;
		.Settings-choosePlayerType {
			width: 34.18em;
			height: 21.88em;
			@include flex('center', '', 'row');
			flex-wrap: wrap;
			.Settings-choosePlayer,
			.Settings-chooseProjector {
				background-color: white;
				width: 13.96em;
				height: 9.94em;
				border-radius: 0.56em;
				.Settings-choosePlayerTypeTitle {
					padding-top: 3.58em;
					span {
						color: $text-dark;
						font-size: 1.11em;
						font-weight: 700;
					}
				}
				.Settings-buttonWrapper {
					margin-top: 1.85em;
					padding-left: 0.67em;
					padding-right: 0.51em;
				}
				&.player-1 {
					margin-right: 6.26em;
					
				}
			}
			.Settings-chooseProjector {
				margin-bottom: 2.01em;
				margin-left: 10.33em;
				margin-right: 9.88em
			}
			.Settings-choosePlayer {
				&::after {
					content: '';
					position: absolute;
					z-index: 2;
					width: 1.65em;
					height: 2.45em;
					margin-top: -8.5em;
					margin-left: 4.5em;					
				}
				&.player-1::after {
					background-image: url(../../assets/images/icons/icon-user-one.svg);
				}
				&.player-2::after {
					background-image: url(../../assets/images/icons/icon-user-two.svg);
				}
			}
		}
	}

	.Settings-navigation {
		@include flex('space-between', 'center');
		width: 5em;
		z-index: 2;
	}
}