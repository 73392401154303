@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Timer {
	position: relative;
	height: 10.7395em;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: 0.42em;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 2.4665em;
		width: calc(2.6em*2);
		background-image: url(../../../assets/images/icons/icon-watch.svg);
		background-size: 2.6em 2.4665em;
		background-position: right 0.71em top 0em;

	}
	&.pulseScale {
		&::after{
			@include pulseScale(0.5s);
		}
	}
	.Timer-window {
		background-color: $white;
		height: 10em;
		border-radius: 0.5em;
		width: 100%;
		padding-left: 1.025em;
		padding-top: 0.57em;
		@include box-shadow(0em, 0.15em, 0.4em, 0.1em, rgba(black, 0.2));
		span {
			text-transform: uppercase;
			font-family: neo-sans, sans-serif;
			font-weight: 700;
			font-style: normal;
			color: $text-dark;
			font-size: 0.9em;
		}
	}
	.Timer-chart {
		position: relative;
		height: 6.8em;
		width: 6.8em;
		margin-top: 0.5em;
		margin-left: 0.3em;

		.Timer-backgroundCircle {
			position: absolute;
			left: calc(50% - (6.7em / 2));
			top: calc(50% - (6.7em / 2));
			height: 6.7em;
			width: 6.7em;
			border-radius: 10em;
			background-color: $yellow-complete;

			&.pulseScale {
				@include pulseColor-timer(1s);
			}
		}

		.Timer-circle {
			position: absolute;
			left: calc(50% - (6.8em / 2));
			top: calc(50% - (6.8em / 2));
			height: 100%;
			width: 100%;
			border-radius: 10em;
			@include scaleX(-1);
		}

		.Timer-centerCircle {
			position: absolute;
			background-color: $white;
			border-radius: 10em;
			left: calc(50% - (5.25em / 2));
			top: calc(50% - (5.25em / 2));
			width: 5.25em;
			height: 5.25em;
		}

		.Timer-clock {
			height: 100%;
			width: 100%;
			@include flex('center', 'center');

			span {
				position: absolute;
				font-size: 1.6em;
				font-weight: 900;
				color: $text-dark;
			}
		}
	}
}